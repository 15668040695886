import { createContext, PropsWithChildren, useContext } from "react";
import type { Analytics } from "firebase/analytics";
import type { FirebasePerformance } from "firebase/performance";

export type InstrumentationContextInterface = {
  firebaseAnalytics: Analytics | undefined;
  firebasePerformance: FirebasePerformance | undefined;
};

export const InstrumentationContext =
  createContext<InstrumentationContextInterface>({
    firebaseAnalytics: undefined,
    firebasePerformance: undefined,
  });

export type InstrumentationProviderProps =
  PropsWithChildren<InstrumentationContextInterface>;
export const InstrumentationProvider = ({
  children,
  firebaseAnalytics,
  firebasePerformance,
}: InstrumentationProviderProps) => {
  return (
    <InstrumentationContext.Provider
      value={{
        firebaseAnalytics,
        firebasePerformance,
      }}
    >
      {children}
    </InstrumentationContext.Provider>
  );
};

export const useInstrumentation = () => useContext(InstrumentationContext);
