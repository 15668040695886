import React, { PropsWithChildren } from "react";
import { globalStyles } from "../../styles/global";
import { ModalProvider } from "../Modal/Modal";
import { OjaUI, ThemeOptions } from "./OjaUIProvider";

export const EcommerceUIProvider = ({ children }: PropsWithChildren) => {
  const [theme, setTheme] = React.useState<ThemeOptions>(ThemeOptions.light);
  globalStyles();

  return (
    <OjaUI.Provider
      value={{
        setTheme: (item: ThemeOptions) => setTheme(item),
        theme,
      }}
    >
      <ModalProvider>{children}</ModalProvider>
    </OjaUI.Provider>
  );
};
